import { ref } from '@vue/composition-api'

// import router from 'vue-router';
import axiosClient from '@/helpers/axios'
import useAlertNotification from '@/services/notification/useAlert'
import router from '@/router'
import useAuthUser from '../authentification/userService'

export default function useExpertises() {
  const { errorToast, successToast } = useAlertNotification()

  const loader = ref(false)
  const offreProcess = ref(false)
  const offreSuccess = ref(false)
  const Success = ref(false)
  const expertiseSuccess = ref(false)
  const errors = ref('')
  const commentaires = ref('')
  const commentaire = ref('')
  const coordonnees = ref('')
  const ExpertisesByUser = ref([])
  const expertises = ref([])
  const expertise = ref([])

  const {
    getAuthUser,
    role,
  } = useAuthUser()

  // Ajouter une documentation
  const createCommentaire = async data => {
    errors.value = ''
    offreProcess.value = true
    await axiosClient.post('/ajouter-commentaire-expertise', data)
      .then(response => {
        if (response.data.success === true) {
          successToast('Succès', response.data.message)
          commentaire.value = response.data.data
          offreSuccess.value = true
          offreProcess.value = false
        }
      })
      .catch(error => {
        offreProcess.value = false
        offreSuccess.value = false
        if (error.response.status === 403) {
          errorToast('403 Forbidden', 'Vous n\'êtes pas autorisé(e) à effectuer cette action')
        } else {
          // errorToast('Oups! Erreur', error.response.data.message)
          errors.value = error.response.data.errors
          const createUserErrors = error.response.data.errors

          Object.keys(createUserErrors).forEach(key => {
            errorToast('Oups! Erreur', createUserErrors[key][0])
          })
        }
      })
  }

  const saveAppreciation = async data => {
    errors.value = ''
    offreProcess.value = true
    await axiosClient.post('/ajouter-appreciation', data)
      .then(response => {
        if (response.data.success === true) {
          successToast('Succès', response.data.message)
          offreSuccess.value = true
          offreProcess.value = false
        }
      })
      .catch(error => {
        offreProcess.value = false
        offreSuccess.value = false
        if (error.response.status === 403) {
          errorToast('403 Forbidden', 'Vous n\'êtes pas autorisé(e) à effectuer cette action')
        } else {
          // errorToast('Oups! Erreur', error.response.data.message)
          errors.value = error.response.data.errors
          const createUserErrors = error.response.data.errors

          Object.keys(createUserErrors).forEach(key => {
            errorToast('Oups! Erreur', createUserErrors[key][0])
          })
        }
      })
  }

  const getAllCommentByExpertises = async () => {
    const response = await axiosClient.get(`/get-all-commentaire-by-expertises/${router.currentRoute.params.id}`)
    commentaires.value = response.data.data
  }

  // Ajouter un moyen de transport
  const handleCreateTransporteurExpertise = async data => {
    errors.value = ''
    offreProcess.value = true
    try {
      const response = await axiosClient.post('/ajouter-transporteur-expertise', data)
      if (response.data.success === true) {
        successToast('Enregistrement', response.data.message)
        offreSuccess.value = true
        offreProcess.value = false
        await getAuthUser()
        if (role.value === 'Transporteur Indépendant') {
          router.push({ name: 'espace-transporteur.expertises-soumises' })
        } else if (role.value === 'Société de transport') {
          router.push({ name: 'espace-societe.expertises-soumises' })
        } else if (role.value === 'Affrêteur') {
          router.push({ name: 'espace-affreteur.expertises-soumises' })
        }
      }
    } catch (error) {
      offreProcess.value = false
      offreSuccess.value = false
      console.log(error)
      if (error.response.status === 403) {
        errorToast('403 Forbidden', 'Vous n\'êtes pas autorisé(e) à effectuer cette action')
      } else {
        errors.value = error.response.data.errors
        const createOffreErrors = error.response.data.errors

        Object.keys(createOffreErrors).forEach(key => {
          errorToast('Oups! Erreur', createOffreErrors[key][0])
        })
      }
    }
  }
  // Liste des transporteurs internes
  const getExpertisesByUserByStatut = async statut => {
    loader.value = true

    axiosClient.get(`/get-all-expertise-by-user-by-status/${statut}`).then(response => {
      if (response.data.success === true) {
        loader.value = false
        ExpertisesByUser.value = response.data.data
      }
    })
  }
  const getAllExpertisesByUser = async () => {
    loader.value = true

    axiosClient.get('/get-all-expertises-by-user').then(response => {
      if (response.data.success === true) {
        loader.value = false
        ExpertisesByUser.value = response.data.data.filter(exp => exp.statut === 'En Cours' || exp.statut === 'Soumise')
      }
    })
  }
  const getAllExpertises = async statut => {
    loader.value = true
    axiosClient.get('/get-all-expertises-by-user').then(response => {
      if (response.data.success === true) {
        loader.value = false
        expertises.value = response.data.data
        // eslint-disable-next-line no-shadow
        expertises.value = response.data.data.filter(expertise => expertise.statut === statut)
      }
    })
  }
  // const generateCoordinates = data => {
  //   const expertiseCounts = {}
  //   const coordinates = []

  //   data.forEach(entry => {
  //     const expertId = entry.experts[0].id
  //     expertiseCounts[expertId] = (expertiseCounts[expertId] || 0) + 1
  //   })

  //   // eslint-disable-next-line no-restricted-syntax, guard-for-in
  //   for (const expertId in expertiseCounts) {
  //     const expert = data[0].experts.find(e => e.id === expertId)
  //     const coordinatesItem = {
  //       fullName: `${expert.lastname} ${expert.firstname}`,
  //       count: expertiseCounts[expertId],
  //     }
  //     coordinates.push(coordinatesItem)
  //   }
  //   return coordinates
  // }
  const generateCoordinates = data => {
    const expertiseCounts = {}
    const experts = []
    const counts = []

    data.forEach(entry => {
      const expertId = entry.experts[0].id
      expertiseCounts[expertId] = (expertiseCounts[expertId] || 0) + 1
    })

    // eslint-disable-next-line no-restricted-syntax, guard-for-in
    for (const expertId in expertiseCounts) {
      const expert = data[0].experts.find(e => e.id === +expertId)
      console.log(expert)
      experts.push(expert.username)
      counts.push(expertiseCounts[expertId])
    }
    // eslint-disable-next-line no-sequences
    // coordonnees.value = 
    return {
      x: experts,
      y: counts,
    }
  }
  const getAllExpertisesByStatut = async (statut, type) => {
    loader.value = true

    axiosClient.get(`/get-all-expertises-by-status/${statut}`).then(response => {
      if (response.data.success === true) {
        const data = response.data.data.filter(exp => exp.type_expert_id === type)
        // eslint-disable-next-line no-const-assign
        expertises.value = generateCoordinates(data)
        loader.value = false
      }
    })
  }
  const findExpertiseById = async () => {
    loader.value = true

    axiosClient.get(`/find-expertise-byid/${router.currentRoute.params.id}`).then(response => {
      if (response.data.success === true) {
        loader.value = false
        expertise.value = response.data.data
      }
    })
  }
  // Supprimer une documentation
  const deleteExpertiseById = async id => {
    await axiosClient.delete(`/del-expertise/${id}`).then(response => {
      if (response.data.success === true) {
        successToast('Supression', response.data.message)
        offreSuccess.value = true
        offreProcess.value = false
        getAllExpertisesByUser()
      }
    })
      .catch(error => {
        offreProcess.value = false
        offreSuccess.value = false
        if (error.response.status === 403) {
          errorToast('403 Forbidden', 'Vous n\'êtes pas autorisé(e) à effectuer cette action')
        } else {
          errorToast('Oups! Erreur', error.response.data.message)
          errors.value = error.response.data.errors
        }
      })
  }
  const sendExpertiseFiledata = async data => {
    loader.value = true
    try {
      const response = await axiosClient.post('/ajouter-transporteur-expertise-file', data)
      if (response.data.success === true) {
        loader.value = false
        successToast('Enregistrement', response.data.message)
        await findExpertiseById()
      }
    } catch (error) {
      loader.value = false
      if (error.response.status === 403) {
        errorToast('403 Forbidden', 'Vous n\'êtes pas autorisé(e) à effectuer cette action')
      } else {
        errors.value = error.response.data.errors
        const createOffreErrors = error.response.data.errors

        Object.keys(createOffreErrors).forEach(key => {
          errorToast('Oups! Erreur', createOffreErrors[key][0])
        })
      }
    }
  }
  const deleteFileData = async id => {
    errors.value = ''
    try {
      const response = await axiosClient.delete(`/delete-file-data/${id}`)
      if (response.data.success === true) {
        successToast('Suppression', response.data.message)
        await findExpertiseById()
      }
    } catch (error) {
      offreSuccess.value = false
      console.log(error)
      if (error.response.status === 403) {
        errorToast('403 Forbidden', 'Vous n\'êtes pas autorisé(e) à effectuer cette action')
      } else {
        errors.value = error.response.data.errors
        const createOffreErrors = error.response.data.errors

        Object.keys(createOffreErrors).forEach(key => {
          errorToast('Oups! Erreur', createOffreErrors[key][0])
        })
      }
    }
  }

  const changeStatusExpertise = async (id, data) => {
    try {
      offreProcess.value = true
      // findExpertiseById()
      // if(expertise.value.experts.length)
      const response = await axiosClient.patch(`/change-status-expertise/${id}`, data)
      if (response.data.success === true) {
        successToast('Validation', response.data.message)
        findExpertiseById()
        Success.value = true
      } else {
        errorToast('Ooops!', response.data.message)
        Success.value = false
      }
    } catch (error) {
      Success.value = false
      if (error.response.status === 403) {
        errorToast('403 Forbidden', 'Vous n\'êtes pas autorisé(e) à effectuer cette action')
      } else {
        console.log(error)
        errorToast('Oups! Erreur', error.response.data.message)
      }
    }
  }

  return {
    errors,
    expertises,
    offreProcess,
    offreSuccess,
    loader,
    expertiseSuccess,
    getAllCommentByExpertises,
    handleCreateTransporteurExpertise,
    deleteExpertiseById,
    sendExpertiseFiledata,
    generateCoordinates,
    getExpertisesByUserByStatut,
    getAllExpertisesByUser,
    ExpertisesByUser,
    getAllExpertises,
    getAllExpertisesByStatut,
    findExpertiseById,
    commentaires,
    coordonnees,
    saveAppreciation,
    commentaire,
    changeStatusExpertise,
    expertise,
    createCommentaire,
    deleteFileData,
    Success,
  }
}
